// Generated by Framer (e1022ef)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import TrendItem from "https://framerusercontent.com/modules/mONHPxq0MUGH4lqTCZ1E/nK2k2sv7mDjCBt0ZxAga/O2k06gpGn.js";
const TrendItemFonts = getFonts(TrendItem);

const enabledGestures = {zfQSEceSE: {hover: true}};

const cycleOrder = ["zfQSEceSE"];

const serializationHash = "framer-GIMxr"

const variantClassNames = {zfQSEceSE: "framer-v-1yxom68"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, title, trending, tweetCount, width, ...props}) => { return {...props, EbeqeFWTe: trending ?? props.EbeqeFWTe ?? "Trending", pjl633gE4: tweetCount ?? props.pjl633gE4 ?? "87.8K Tweets", qRruJRNGu: title ?? props.qRruJRNGu ?? "Framer"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;trending?: string;title?: string;tweetCount?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, EbeqeFWTe, qRruJRNGu, pjl633gE4, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "zfQSEceSE", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1yxom68", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"zfQSEceSE"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgba(0, 0, 0, 0)", ...style}} variants={{"zfQSEceSE-hover": {backgroundColor: "rgb(29, 32, 34)"}}} {...addPropertyOverrides({"zfQSEceSE-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1ipvp3j-container"} layoutDependency={layoutDependency} layoutId={"scedA00pZ-container"}><TrendItem height={"100%"} id={"scedA00pZ"} layoutId={"scedA00pZ"} style={{width: "100%"}} title={qRruJRNGu} trending={EbeqeFWTe} tweetCount={pjl633gE4} width={"100%"}/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-GIMxr[data-border=\"true\"]::after, .framer-GIMxr [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-GIMxr.framer-2627l2, .framer-GIMxr .framer-2627l2 { display: block; }", ".framer-GIMxr.framer-1yxom68 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 10px 16px 10px 16px; position: relative; width: 342px; }", ".framer-GIMxr .framer-1ipvp3j-container { flex: 1 0 0px; height: auto; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-GIMxr.framer-1yxom68 { gap: 0px; } .framer-GIMxr.framer-1yxom68 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-GIMxr.framer-1yxom68 > :first-child { margin-left: 0px; } .framer-GIMxr.framer-1yxom68 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 82
 * @framerIntrinsicWidth 342
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"z3KwRVMBd":{"layout":["fixed","auto"]}}}
 * @framerVariables {"EbeqeFWTe":"trending","qRruJRNGu":"title","pjl633gE4":"tweetCount"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerIvxbPBdVn: React.ComponentType<Props> = withCSS(Component, css, "framer-GIMxr") as typeof Component;
export default FramerIvxbPBdVn;

FramerIvxbPBdVn.displayName = "Trendd";

FramerIvxbPBdVn.defaultProps = {height: 82, width: 342};

addPropertyControls(FramerIvxbPBdVn, {EbeqeFWTe: {defaultValue: "Trending", displayTextArea: false, title: "Trending", type: ControlType.String}, qRruJRNGu: {defaultValue: "Framer", displayTextArea: false, title: "Title", type: ControlType.String}, pjl633gE4: {defaultValue: "87.8K Tweets", displayTextArea: false, title: "Tweet Count", type: ControlType.String}} as any)

addFonts(FramerIvxbPBdVn, [...TrendItemFonts])